
.App {
  text-align: center;
}
 

.Education{
  text-align: center;
  color: rgb(5, 1, 1);
  text-decoration: saddlebrown;
  
  border: solid 1px #555;
  border-radius: 15px;
  margin: 10px 40px;
  background-color: rgb(239, 240, 238);
  box-shadow: 12px 0 15px -4px rgba(15, 25, 37, 0.8), -12px 0 8px -4px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 12px 0 15px -4px rgba(21, 29, 40, 0.8), -12px 0 8px -4px rgba(18, 21, 24, 0.8);
  -webkit-box-shadow: 12px 0 15px -4px rgba(31, 119, 227, 0.8), -12px 0 8px -4px rgba(68, 150, 232, 0.8);
  -o-box-shadow: 12px 0 15px -4px rgba(36, 122, 235, 0.8), -12px 0 8px -4px rgba(71, 115, 205, 0.8);
}

.Certificates{
  text-align: center;
  color: rgb(5, 1, 1);
  text-decoration: salmon;
  
  border: solid 1px #555;
  border-radius: 15px;
  margin: 10px 40px;
  background-color: rgb(239, 240, 238);
  box-shadow: 12px 0 15px -4px rgba(15, 25, 37, 0.8), -12px 0 8px -4px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 12px 0 15px -4px rgba(21, 29, 40, 0.8), -12px 0 8px -4px rgba(18, 21, 24, 0.8);
  -webkit-box-shadow: 12px 0 15px -4px rgba(31, 119, 227, 0.8), -12px 0 8px -4px rgba(68, 150, 232, 0.8);
  -o-box-shadow: 12px 0 15px -4px rgba(36, 122, 235, 0.8), -12px 0 8px -4px rgba(71, 115, 205, 0.8);
}

.Internships{
  text-align: center;
  color: rgb(5, 1, 1);
  text-decoration: salmon;
  
  border: solid 1px #555;
  border-radius: 15px;
  margin: 10px 40px;
  background-color: rgb(239, 240, 238);
  box-shadow: 12px 0 15px -4px rgba(15, 25, 37, 0.8), -12px 0 8px -4px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 12px 0 15px -4px rgba(21, 29, 40, 0.8), -12px 0 8px -4px rgba(18, 21, 24, 0.8);
  -webkit-box-shadow: 12px 0 15px -4px rgba(31, 119, 227, 0.8), -12px 0 8px -4px rgba(68, 150, 232, 0.8);
  -o-box-shadow: 12px 0 15px -4px rgba(36, 122, 235, 0.8), -12px 0 8px -4px rgba(71, 115, 205, 0.8);
}

.Skills{
  text-align: center;
  color: rgb(5, 1, 1);
  text-decoration: salmon;
  
  border: solid 1px #555;
  border-radius: 15px;
  margin: 10px 40px;
  background-color: rgb(239, 240, 238);
  box-shadow: 12px 0 15px -4px rgba(15, 25, 37, 0.8), -12px 0 8px -4px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 12px 0 15px -4px rgba(21, 29, 40, 0.8), -12px 0 8px -4px rgba(18, 21, 24, 0.8);
  -webkit-box-shadow: 12px 0 15px -4px rgba(31, 119, 227, 0.8), -12px 0 8px -4px rgba(68, 150, 232, 0.8);
  -o-box-shadow: 12px 0 15px -4px rgba(36, 122, 235, 0.8), -12px 0 8px -4px rgba(71, 115, 205, 0.8);
}

.Projects{
  text-align: center;
  color: rgb(5, 1, 1);
  text-decoration: salmon;
  
  border: solid 1px #555;
  border-radius: 15px;
  margin: 10px 40px;
  background-color: rgb(239, 240, 238);
  box-shadow: 12px 0 15px -4px rgba(15, 25, 37, 0.8), -12px 0 8px -4px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 12px 0 15px -4px rgba(21, 29, 40, 0.8), -12px 0 8px -4px rgba(18, 21, 24, 0.8);
  -webkit-box-shadow: 12px 0 15px -4px rgba(31, 119, 227, 0.8), -12px 0 8px -4px rgba(68, 150, 232, 0.8);
  -o-box-shadow: 12px 0 15px -4px rgba(36, 122, 235, 0.8), -12px 0 8px -4px rgba(71, 115, 205, 0.8);
}

.Languages{
  text-align: center;
  color: rgb(5, 1, 1);
  text-decoration: salmon;
  
  border: solid 1px #555;
  border-radius: 15px;
  margin: 10px 40px;
  background-color: rgb(239, 240, 238);
  box-shadow: 12px 0 15px -4px rgba(15, 25, 37, 0.8), -12px 0 8px -4px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 12px 0 15px -4px rgba(21, 29, 40, 0.8), -12px 0 8px -4px rgba(18, 21, 24, 0.8);
  -webkit-box-shadow: 12px 0 15px -4px rgba(31, 119, 227, 0.8), -12px 0 8px -4px rgba(68, 150, 232, 0.8);
  -o-box-shadow: 12px 0 15px -4px rgba(36, 122, 235, 0.8), -12px 0 8px -4px rgba(71, 115, 205, 0.8);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    flex-direction: column;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Logo{
  size-adjust: 5%;  
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: bottom;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.display {display:block;}

.show{
  border: solid 1px #555;
  border-radius: 15px;
  margin: 10px 40px;
  background-color: rgb(239, 240, 238);
  box-shadow: 12px 0 15px -4px rgba(15, 25, 37, 0.8), -12px 0 8px -4px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 12px 0 15px -4px rgba(21, 29, 40, 0.8), -12px 0 8px -4px rgba(18, 21, 24, 0.8);
  -webkit-box-shadow: 12px 0 15px -4px rgba(31, 119, 227, 0.8), -12px 0 8px -4px rgba(68, 150, 232, 0.8);
  -o-box-shadow: 12px 0 15px -4px rgba(36, 122, 235, 0.8), -12px 0 8px -4px rgba(71, 115, 205, 0.8);
}

.hide{
  background-color: rgb(242, 246, 247);
}